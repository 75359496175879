/* constants.css */
:root {
    /* ------------------------------- */
    --desktop-heading-font-size1: 73px;
    --desktop-heading-font-size2: 28px;
    --desktop-normal-font-size: 15px;
    --desktop-button-font-size: 15px;
    /* ------------------------------- */
    --tablet-heading-font-size1: 48px;
    --tablet-heading-font-size2: 28px;
    --tablet-normal-font-size: 15px;
    --tablet-button-font-size: 15px;
    /* ------------------------------- */
    --mobile-heading-font-size1: 33px;
    --mobile-heading-font-size2: 28px;
    --mobile-normal-font-size: 15px;
    --mobile-button-font-size: 15px;
}